import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import StorageService from "./storage.service";
import { errorNotification } from "../globals/helpers";

// Function to get user's IP address
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getUserIP() {
  try {
    const response = await axios.get("https://api.ipify.org");
    return response.data;
  } catch (error) {
    console.error("Error fetching IP:", error);
    throw error;
  }
}

const instance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async function (config) {
    //TODO adding IP in every calls
    // const userIP = await getUserIP(); // Wait for the IP address to be fetched
    // config.headers["X-User-IP"] = userIP; // Assuming the API expects the IP in a header field
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// TODO: remove the automatic error notification, and handle that in a more controlled way
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if (error?.response?.status) {
      if (error.response?.headers?.["show-toast"] !== "False") {
        errorNotification(
          error?.response?.data?.error ??
            error?.response?.data ??
            "Something went wrong"
        );
      } else {
        /** TODO we can write what we want instead on toast */
      }
    }

    if (error.response && error.response.status === 400) {
      return Promise.resolve({
        data: error.response?.data || null, // Error from the backend
        status: 400,
        statusText: "Bad Request",
        message: error.response?.data?.error || "Invalid request",
      });
    }

    if (error.response && error.response.status === 401) {
      return setTimeout(() => {
        StorageService.removeUser();
        window.location.pathname = "/login";
        Promise.resolve({
          data: null,
          status: 401,
          statusText: "Unauthorized",
          message: "Unauthorized access, logging out...",
        });
      }, 2000);
    }

    return Promise.reject(error);
  }
);

export default instance;
